<template>
  <section>
    <div class="entity-editor-container">
      <div class="document-container-wrapper" >
          <div class="form-builder" style="height:45vh;overflow-y:auto;scrollbar-width:thin;">
            <div>
              <div class="emp-box mt-1 mr-2" v-if="!selectedEntity.length">
                <h3 class="head pt-1 pb-1 text-center" v-if="isEdit">
                  Add more Templates to build most powerful Entity
                </h3>
                <h3 class="head pt-1 pb-1" v-else>Start Building Entity!</h3>
                <p class="text pb-1 text-center">
                  Drag templates from the right panel and drop here to add them
                </p>
              </div>
              <div v-if="selectedEntity.length" class="entity-steps">
                <draggable
                  v-model="selectedEntity"
                  v-if="selectedEntity && selectedEntity.length"
                >
                  <transition-group tag="div" name="flip-list" id="field-group">
                    <div v-for="(step, index) of selectedEntity" :key="index">
                      <div class="each-step">
                        <span class="arrow-down"></span>
                        <el-row>
                          <el-col :span="2">
                            <icons :iconName="step.icon ? step.icon :'dashboard'"></icons>
                          </el-col>
                          <el-col :span="20">
                            <div class="name-box">
                              <span>Step {{ index + 1 }}</span>
                              <div class="icon-container">
                                {{
                                  step.senderAlias
                                    ? step.senderAlias
                                    : step.templateInfo.name
                                      | truncate(40, "...")
                                }}                 
                                <i
                                  class="el-icon-info icon-class"
                                  @click="openAliasPopUp(index)"
                                ></i>
                                <i
                                  v-if="
                                    step.templateInfo.slug != 'contactdetails'
                                  "
                                  class="el-icon-view icon-class"
                                  @click="showDetailedViewOfFormTemplate(step)"
                                ></i>
                                <i
                                  class="el-icon-setting icon-class"
                                  @click="openTemplateSettings(index)"
                                ></i>
                            </div>
                            </div>
                          </el-col>
                          <el-col :span="2">
                            <el-button
                              type="danger"
                              icon="el-icon-delete"
                              class="delete-btn"
                              size="mini"
                              style="margin-top: 18px"
                              v-if="
                                !(
                                  step.templateInfo &&
                                  step.templateInfo.type == 'STANDARD' &&
                                  entityType == 'INDIVIDUAL'
                                )
                              "
                              @click="deleteTemplate(index)"
                            ></el-button>
                          </el-col>
                        </el-row>
                      </div>
                    </div>
                  </transition-group>
                </draggable>
              </div>
            </div>
          </div>
      </div>
      <div class="right-side-bar">
        <div class="right-temp-block">
          <div style="text-align: center">
            <h3 class="temp mt-1">Templates</h3>
          </div>
          <el-divider></el-divider>
          <el-input
            placeholder="Search by template name"
            class="mb-1 ml-1 mr-2"
            :style="getIsMobile ? 'width:190px' : 'width: 300px'"
            v-model="search_string"
            clearable
          >
          </el-input>
          <div class="scrollable-template">
            <!-- <el-scrollbar
              :wrap-style="
                getIsMobile
                  ? 'max-height: calc(100vh - 200px);'
                  : 'max-height: calc(100vh - 182px);'
              "
            > -->
              <router-link
                v-if="
                  !this.allTemplates.length ||
                  this.allTemplates.length == 0 ||
                  !this.allTemplates
                "
                to="/template"
              >
                <el-button
                  class="mb-1 ml-2 mr-1"
                  style="width: 300px"
                  type="primary"
                >
                  Add Form Templates
                </el-button>
              </router-link>

              <el-button
                v-for="(temp, i) in this.allTemplates"
                :key="`temp.slug_${i}`"
                class="temp-box mb-1 mr-1 ml-1"
                @click="addTemplate(temp)"
                :disabled="checkIsUsedTemplate(temp)"
              >
                <div class="icon-block">
                  <icons :iconName="temp.icon ? temp.icon : 'dashboard'"></icons>
                  <p>
                    {{ temp.name | truncate(40, "...") }}
                  </p>
                </div>
              </el-button>
            <!-- </el-scrollbar> -->
          </div>
        </div>
      </div>
    </div>
    <dialog-component
      :title="'Sender/Receiver Display Names'"
      :visible="openAliasPopUpModal"
      class="doc-modal sender-receiver-display-names-popup"
      v-loading="modalLoading"
      element-loading-text="Loading..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      @before-close="clearfileds"
      :containerWidth="'40%'"
    >
      <div class="mt-3">
        <span>
          <label>Sender Display Name :</label>
          <el-input
            type="text"
            placeholder="Sender Display Name"
            v-model="alias.senderAlias"
          ></el-input>
        </span>
        <span>
          <label>Receivers Display Name :</label>
          <el-input
            type="text"
            placeholder="Receivers Display Name"
            v-model="alias.receiverAlias"
          ></el-input>
        </span>
        <span>
          <el-checkbox v-model="alias.is_public" class="mt-1">
            Public template
          </el-checkbox>
        </span>
        <br />
        <span
          class="d-flex flex-row"
          v-if="
            !(
              alias &&
              alias.templateInfo &&
              alias.templateInfo.type == 'STANDARD'
            )
          "
        >
          <el-checkbox
            v-model="alias.is_repeatable"
            :disabled="checkTemplateData(alias.template_id)"
            class="mt-1 mb-1"
            @change="notifiUserForRepeatableTemp"
          >
            Repeatable template
          </el-checkbox>
        </span>
        <!-- <span
          v-if="
            alias && alias.templateInfo && alias.templateInfo.type == 'STANDARD'
          "
        >
          Required fields in standrad template only applicable while adding in
          entity side
          <el-select v-model="alias.configuration" multiple>
            <el-option value="middle_name" label="Middle Name"></el-option>
            <el-option value="title" label="Title"></el-option>
            <el-option value="address" label="Address"></el-option>
            <el-option value="phone_number" label="Phone Number"></el-option>
            <el-option value="name" label="Name"></el-option>
          </el-select>
        </span> -->
        <span v-if="alias.is_repeatable">
          <label>Type of Display</label>
          <el-select
            v-model="alias.default_display"
            size="mini"
            class="ml-1"
            placeholder="TABLE"
          >
            <el-option
              v-for="item in repeatableDisplaydata"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select> </span
        ><br />
        <span v-if="alias.is_repeatable">
          <br />
          <label>Min number of template data can be added</label><br />
          <el-select
            placeholder="Select field"
            v-model="alias.min_linked_field"
            size="mini"
            clearable
            class="ml-1"
          >
            <el-option
              v-for="(op, i) in getPrevStepFields"
              :key="op.key + i"
              :value="op.key"
              :label="op.label"
            >
              {{ op.label }}
            </el-option>
          </el-select>
          <span v-if="!alias.min_linked_field">OR</span>
          <el-input-number
            v-if="!alias.min_linked_field"
            v-model="alias.min_records"
            size="mini"
            class="ml-1"
            :min="0"
          ></el-input-number>
        </span>
        <span v-if="alias.is_repeatable">
          <br />
          <br />
          <label>Max number of template data can be added</label><br />
          <el-select
            placeholder="Select field"
            v-model="alias.max_linked_field"
            size="mini"
            clearable
            class="ml-1"
          >
            <el-option
              v-for="(op, i) in getPrevStepFields"
              :key="op.key + i"
              :value="op.key"
              :label="op.label"
            >
              {{ op.label }}
            </el-option>
          </el-select>
          <span v-if="!alias.max_linked_field">OR</span>
          <el-input-number
            v-if="!alias.max_linked_field"
            v-model="alias.max_records"
            size="mini"
            class="ml-1"
            :min="0"
          ></el-input-number>
        </span>
      </div>
      <span slot="footer" class="dialog-footer">
          <div>
            <el-button @click="clearfileds">Cancel</el-button>
            <el-button type="primary" @click="updateAliasNames"
              >Submit</el-button
            >
          </div>
        </span>
    </dialog-component>
    <dialog-component
      :title="this.previewTemplateName"
      :visible="showDetailedView"
      :containerWidth="getIsMobile ? '100%' : '70%'"
      :containerMaxHeight="'90%'"
      v-loading="modalLoading"
      element-loading-text="Please wait, we are getting things ready for you."
      element-loading-spinner="pencil"
      element-loading-background="rgba(202, 197, 197, 0.173)"
      class="detailedTemplatesView"
      @before-close="resetTemplateData"
      :isShowFooter="false"
    >
      <div v-if="modalLoading"><Loader :templateIdViewId="templateId" /></div>
      <!-- <el-scrollbar
        :wrap-style="`max-width: calc(100% - 0px);height: calc(100vh - 50px);`"
      > -->
        <viewTemplate
          v-if="showDetailedView && !modalLoading"
          :templateIdViewId="templateId"
        />
      <!-- </el-scrollbar> -->
    </dialog-component>

    <!-- Settings modal -->
    <dialog-component
      :title="'Customization'"
      :visible="editingTemplateModal"
      @before-close="saveCustomization"
      :isShowFooter="false"
    >
      <div>
        <EntityTemplateCustomization
          :templateData="editingTemplateData"
          :customization="editingTemplateCustomization"
        ></EntityTemplateCustomization>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="resetTemplateSettings">cancel</el-button>
        <el-button type="primary" @click="saveCustomization">Save</el-button>
      </span> -->
    </dialog-component>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import CompanyDocumentsHelper from "@/mixins/CompanyDocumentsHelper";
import viewTemplate from "@/components/templates/ViewTemplate";
import Loader from "@/views/loader.vue";
import { getAllCompanyTemplatesByPagination } from "@/repo/templatesRepo";

export default {
  name: "Entity-DropTemplates",
  components: {
    draggable: () => import("vuedraggable"),
    EntityTemplateCustomization: () => import("./EntityTemplateCustomization"),
    viewTemplate,
    Loader,
  },
  props: [
    "allSelectedEntity",
    "allTemplates",
    "isEdit",
    "entitytemplateData",
    "isSubmit",
    "entityType",
  ],
  mixins: [CompanyDocumentsHelper],
  computed: {
    ...mapGetters("navigationOpen", ["getIsMobile"]),
    // ...mapGetters("companyTemplates", ["getAllCompanyTemplatesData"]),
    getPrevStepFields() {
      let prevTemplates = [];
      let result = [];
      this.selectedEntity.forEach((step, index) => {
        if (index < this.aliasIndex) {
          if (!step?.is_repeatable) {
            prevTemplates.push({
              template_id: step.template_id,
              stepIndex: index,
              name: step.receiverAlias,
            });
          }
        }
      });
      if (prevTemplates && prevTemplates.length) {
        prevTemplates.forEach((temp) => {
          result = [
            ...result,
            ...this.getTemplateFields(
              temp.template_id,
              temp.name,
              temp.stepIndex
            ),
          ];
        });
      }
      return result;
    },
  },
  data() {
    return {
      templateId: null,
      showDetailedView: false,
      search_string: "",
      previewTemplateName: "",
      repeatableDisplaydata: [
        {
          value: "TABLE",
          label: "TABLE",
        },
        {
          value: "KANBAN",
          label: "KANBAN",
        },
      ],
      loading: false,
      selectedEntity: [],
      modalLoading: false,
      openAliasPopUpModal: false,
      aliasIndex: 0,
      // allEntitytemplateData: [],
      alias: {
        template_id: "",
        senderAlias: "",
        receiverAlias: "",
        isActive: true,
        templateInfo: {},
        is_repeatable: false,
        is_public: false,
        max_records: 3,
        max_linked_field: "",
        min_linked_field: "",
        min_records: 1,
        default_display: "TABLE",
        customization: {},
      },
      editingTemplateIndex: -1,
      editingTemplateCustomization: null,
      editingTemplateData: null,
      editingTemplateModal: false,
      getCompanyTemplatesData: null,
    };
  },
  watch: {
    isSubmit() {
      this.$emit("getTemplates", this.selectedEntity);
    },
    search_string: function (val) {
      this.search_string = val;
      this.fetchTemplates();
    },
  },
  async mounted() {
    this.loading = true;
    if (this.allSelectedEntity) {
      this.selectedEntity = this.allSelectedEntity;
    }
    this.loading = false;
  },
  methods: {
    saveCustomization() {
      this.selectedEntity[this.editingTemplateIndex].customization = {
        ...this.editingTemplateCustomization,
      };
      this.resetTemplateSettings();
    },
    resetTemplateSettings() {
      this.editingTemplateIndex = -1;
      this.editingTemplateCustomization = null;
      this.editingTemplateModal = false;
    },
    openTemplateSettings(index) {
      this.editingTemplateIndex = index;
      this.editingTemplateCustomization =
        this.selectedEntity[index].customization || {};
      this.editingTemplateData = this.selectedEntity[index].templateInfo;
      this.editingTemplateModal = true;
    },
    getTemplateFields(id, name, index) {
      const currentTemplate = (
        this.getCompanyTemplatesData?.data || []
      ).find((e) => e._id == id);
      let result = [];
      if (currentTemplate?.sections[0]?.fields) {
        let tempName = name ? name : currentTemplate.name;
        currentTemplate?.sections[0]?.fields.forEach((fl) => {
          if (fl.inputType == "NUMBER") {
            result.push({
              label: parseInt(index) + 1 + ". " + tempName + "-" + fl.label,
              field: fl.key,
              key: index + "#" + currentTemplate._id + "#" + fl.key,
            });
          }
        });
      }
      return result;
    },
    async fetchTemplates() {
      this.loading = true;
      let params = {
        get_all: true,
      };
      if (this.search_string) {
        params.search_string = this.search_string;
      }
      const getAllCompanyTemplatesData =
        await getAllCompanyTemplatesByPagination(params);
      this.allTemplates =
        getAllCompanyTemplatesData && getAllCompanyTemplatesData.data
          ? getAllCompanyTemplatesData.data
          : [];
      this.getCompanyTemplatesData = { ...getAllCompanyTemplatesData };
      // await this.$store.dispatch(
      //   "companyTemplates/getAllCompanyTemplates",
      //   params
      // );
      // this.allTemplates =
      //   this.getAllCompanyTemplatesData && this.getAllCompanyTemplatesData.data
      //     ? this.getAllCompanyTemplatesData.data
      //     : [];
      this.loading = false;
    },
    checkTemplateData(template_id) {
      if (this.entitytemplateData && this.entitytemplateData.length) {
        let tempData = this.entitytemplateData.find((e) => e.id == template_id);
        if (tempData && tempData.has_data) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
    clearfileds() {
      this.alias.senderAlias = null;
      this.alias.receiverAlias = null;

      this.openAliasPopUpModal = false;

      this.modalLoading = false;
    },
    destroyCreateModal() {
      this.alias = {
        template_id: "",
        senderAlias: "",
        receiverAlias: "",
        isActive: true,
        templateInfo: {},
      };

      this.openAliasPopUpModal = false;

      this.modalLoading = false;
    },
    async showDetailedViewOfFormTemplate(id) {
      this.previewTemplateName = `Preview of  " ${id.templateInfo.name} "`;
      this.modalLoading = true;
      this.templateId = id.template_id;
      this.showDetailedView = true;
      setTimeout(() => {
        this.modalLoading = false;
      }, 4000);
    },
    resetTemplateData() {
      this.templateId = null;
      this.showDetailedView = false;
    },
    openAliasPopUp(index) {
      this.modalLoading = true;
      this.destroyCreateModal();
      (this.aliasIndex = index), (this.alias = this.selectedEntity[index]);
      this.openAliasPopUpModal = true;
      this.modalLoading = false;
    },
    updateAliasNames() {
      this.modalLoading = true;
      this.selectedEntity[this.aliasIndex] = this.alias;
      this.destroyCreateModal();
    },
    checkIsUsedTemplate(item) {
      let exists = this.selectedEntity.find((e) => {
        if (
          e &&
          e.template_id &&
          item &&
          item._id &&
          item._id.toString() == e.template_id.toString()
        ) {
          return e;
        }
      });
      if (exists) {
        return true;
      }
      return false;
    },
    deleteTemplate(i) {
      let currentTemp = this.selectedEntity[i];
      let repeatableTemp = this.selectedEntity.find((e) => e.is_repeatable);
      let nonRepeatableTemp = this.selectedEntity.filter(
        (e) => !e.is_repeatable
      );
      if (
        currentTemp &&
        !currentTemp.is_repeatable &&
        repeatableTemp &&
        repeatableTemp.templateInfo &&
        nonRepeatableTemp &&
        nonRepeatableTemp.length <= 1
      ) {
        this.$message({
          message: `Can't delete. ${
            repeatableTemp.templateInfo.name
              ? repeatableTemp.templateInfo.name +
                " is a repeatable template. So"
              : ""
          } There must be altleast one non-repeatable template.`,
          type: "warning",
        });
      } else if (
        currentTemp &&
        currentTemp.is_repeatable &&
        this.checkTemplateData(currentTemp.template_id)
      ) {
        this.$message({
          message: `Can't delete.This is an repeatable template. Some data is already saved through this template.`,
          type: "warning",
        });
      } else {
        this.selectedEntity.splice(i, 1);
      }
    },
    notifiUserForRepeatableTemp() {
      if (this.checkTemplateData(this.alias.template_id)) {
        if (!this.alias.is_repeatable) {
          this.$message({
            message:
              "Already some repeatable data is filled for this template. Can't change.",
            type: "warning",
          });
          this.alias.is_repeatable = true;
        } else {
          this.$confirm(
            `Some data is stored with this template. Once it convert to repeatable template. You can't revert it back. Are sure to continue?`,
            "Warning",
            {
              confirmButtonText: "Continue?",
              cancelButtonText: "Cancel",
              type: "warning",
            }
          )
            .then(() => {
              this.alias.is_repeatable = true;
            })
            .catch(() => {
              this.alias.is_repeatable = false;
            });
        }
        return;
      }
      let temp = this.checkForNonRepeatableTemplate();
      let primaryFieldCheck = this.templateHasPrimaryField(
        this.alias.template_id
      );
      if (!temp || !primaryFieldCheck) {
        this.alias.is_repeatable = false;
        let message = "";
        if (!temp) {
          message = "Please Add altleast one non-repeatable template";
        } else {
          message =
            "Primary field not selected for this template. Please select on template edit page.";
        }
        this.$message({
          message: message,
          type: "warning",
        });
      }
    },
    checkForNonRepeatableTemplate() {
      let nonRepeatableTemp = this.selectedEntity.find((e) => !e.is_repeatable);
      if (nonRepeatableTemp) {
        return true;
      }
      return false;
    },
    templateHasPrimaryField(id) {
      let selectedTemplate = this.allTemplates.find((e) => e._id == id);
      if (
        selectedTemplate &&
        selectedTemplate.primary_fields &&
        selectedTemplate.primary_fields[0] &&
        selectedTemplate.primary_fields[0].input_type
      ) {
        return true;
      }
      return false;
    },
    async addTemplate(item) {
      let index = this.selectedEntity.length
        ? this.selectedEntity.length + 1
        : 1;
      this.selectedEntity.push({
        template_id: item._id,
        senderAlias: "",
        receiverAlias: "",
        isActive: true,
        templateInfo: item,
        is_repeatable:
          this.checkForNonRepeatableTemplate() &&
          this.templateHasPrimaryField(item._id)
            ? item.is_repeatable
            : false,
        is_public: item.is_public,
        template_order: index,
        max_records: item.max_records || 3,
        min_records: item.min_records || 1,
        max_linked_field: "",
        min_linked_field: "",
        icon: item.icon,
      });
      await this.fecthEntityData();
    },
  },
};
</script>

<style lang="scss">
.el-input-number__increase {
  right: 7px;
}
.el-dialog__body {
  margin-top: 10px;
}
.detailedTemplatesView {
  top: 0px !important;
  left: 0px !important;
  right: 0px !important;
  bottom: 0px !important;
}
.form-builder {
  height: calc(100vh - 100px) !important;
  .entity-steps {
    // margin-top: 30px;
    #field-group {
      padding-top: 20px;
      & > div:last-child {
        padding-bottom: 35px;
        position: relative;
        &:after {
          content: "END";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          width: 70px;
          display: block;
          margin: 0 auto;
          padding: 0.5em 1.5em;
          border-radius: 5px;
          border: 1px dashed #ff3a79;
          text-align: center;
          font-weight: 600;
          letter-spacing: 1px;
          color: #ff3a79;
        }
      }
    }
  }
  // scroll-padding-inline-start: 10px;
  .each-step {
    .name-box {
      border-left: 1px solid #ebeef5;
      border-right: 1px solid #ebeef5;
    }
    &::after {
      content: "";
      position: absolute;
      bottom: -3em;
      left: calc(50% - 1px);
      width: 2px;
      height: 3em;
      background-color: #409eff;
    }
    #field-group {
      & > div:last-child {
        padding-bottom: 35px;
        position: relative;
        &:after {
          content: "END";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          width: 70px;
          display: block;
          margin: 0 auto;
          padding: 0.5em 1.5em;
          border-radius: 5px;
          border: 1px dashed #ff3a79;
          text-align: center;
          font-weight: 600;
          letter-spacing: 1px;
          color: #ff3a79;
        }
      }
    }

    .arrow-down {
      position: absolute;
      left: calc(50% - 8px);
      bottom: -3em;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid #409eff;
      z-index: 10;
    }

    cursor: pointer;
    font-style: normal;
    font-weight: 300;
    position: relative;
    z-index: 10;
    display: block;
    max-width: 30em;
    background: #fdfdfd;
    border: 1px solid #f754a2;
    box-sizing: border-box;
    border-radius: 3px;
    color: #303133;
    padding: 0;
    margin: 0 auto 3em;
    height: 62px;
    .img-fluid {
      width: 25px;
    }
    .delete-btn {
      background: transparent !important;
      border: transparent;
      color: #f754a2;
    }
  }
  .emp-box {
    border: 1px dashed #d2d2d2;
    color: #4b545c;
    font-style: normal;
    padding-left: 10px;
    .head {
      font-weight: 700;
      font-size: 14px;
    }
    .text {
      font-size: 14px;
    }
  }
  background-color: white;
  width: 100%;
  height: 100%;
}
.right-temp-block {
  background: #ffffff;
  //   text-align: center;
  // height: calc(100vh);
  border: 1px solid #dbe5ee;
  .temp {
    color: #4c4b4e;
    font-style: normal;
    font-weight: normal;
  }
  .temp-box {
    border: 1px solid #f1f1f1;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 7px;
    color: #606266;
    width: 100%;
    text-align: left;
    &.is-disabled {
      background-color: #efefef;
      border-color: #e8e5e5;
      cursor: not-allowed;
    }
  }
  .img-fluid {
    width: 25px;
  }
  .icon-block {
    display: grid;
    grid-template-columns: 25px auto;
    gap: 10px;
    align-items: center;

    .icon-text {
      td {
        border-bottom: none !important;
        font-weight: 500;
        color: #000;
      }
    }
  }
}
</style>
<style lang="scss">
.entity-editor-container {
  position: relative;
  // top: 80px;
}
#drag-template-background {
  &.entity-edit-page {
    &.mobile {
      .entity-editor-container {
        .right-side-bar {
          top: 0px;
          width: 220px !important;
          .el-scrollbar {
            padding-top: 0px !important;
          }
        }
      }
    }
    .entity-editor-container {
      .right-side-bar {
        top: 0px;
        @media (max-width: 1200px) {
          width: 220px !important;
        }
      }
    }
    .entity-editor-container {
      display: flex;
      justify-content: space-between;
      .document-container-wrapper {
        width: 100%;
        // height: calc(100vh - 85px);
        .form-builder {
          .workflow-steps {
            margin-top: 0px;
            padding: 20px 0px;
          }
        }
      }
      .right-side-bar {
        width: 337px;
        .el-scrollbar {
          .el-scrollbar__view {
            padding-left: 3px !important;
            padding-right: 3px !important;
            .el-button {
              margin-left: 0px !important;
              margin-right: 0px !important;
              .icon-block {
                & > div {
                  overflow-wrap: break-word;
                  word-wrap: break-word;
                  word-break: break-word;
                  white-space: break-spaces !important;
                  line-height: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.entity-edit-page {
  .sender-receiver-display-names-popup {
    .el-dialog {
      width: 800px;
      padding-bottom: 15px;
      @media (max-width: 800px) {
        width: 700px;
        margin-top: 40px !important;
      }
      @media (max-width: 700px) {
        width: 600px;
        margin-top: 40px !important;
      }
      @media (max-width: 600px) {
        width: 500px;
        margin-top: 40px !important;
      }
      @media (max-width: 500px) {
        width: 400px;
        margin-top: 40px !important;
      }
      @media (max-width: 400px) {
        width: 300px;
        margin-top: 40px !important;
      }
      .entity-type-radio {
        @media (max-width: 400px) {
          & > label {
            & > span {
              padding-left: 5px !important;
              padding-right: 5px !important;
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.scrollable-template{
  height:70vh;
  overflow-y:scroll;
  scrollbar-width:thin;
}
.icon-container {
  display: -webkit-box;
}
.icon-class{
  margin-left: 5px !important;
}
</style>
<style scoped>
::v-deep(.detailedTemplatesView .dialog-content) {
    flex-grow: unset !important;
    overflow-y: unset !important;
    scrollbar-width: unset !important;
}
::v-deep(.detailedTemplatesView .form-builder .form-fields-holder) {
  height: 78vh !important;
}
</style>
